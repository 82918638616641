import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { firebaseAuth } from "fb";
import Initial from "screens/Initial";
import SignIn from "screens/SignIn";
import SignUp from "screens/SignUp";
import Forgot from "screens/Forgot";
import Welcome from "screens/Welcome";

export default function App() {
  const [currentUser, setCurrentUser] = useState();

  firebaseAuth.onAuthStateChanged(user => {
    setCurrentUser(user);
  });

  if (!currentUser) {
    return (
      <div style={{ maxWidth: "1200px", margin: "auto" }}>
        <Switch>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/forgot">
            <Forgot />
          </Route>
          <Route path="/">
            <Initial />
          </Route>
        </Switch>
      </div>
    );
  }

  return <Welcome currentUser={currentUser} />;
}
