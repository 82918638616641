import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { MdArrowBack, MdVisibility, MdVisibilityOff } from "react-icons/md";
import {
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast
} from "@chakra-ui/core";
import Header from "components/Header";
import { firebaseAuth } from "fb";

export default () => {
  const toast = useToast();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isOpen: showPassword, onToggle: togglePassword } = useDisclosure(
    false
  );

  const submitForm = e => {
    e.preventDefault();
    setIsLoading(true);
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => history.push("/"))
      .catch(err => {
        setIsLoading(false);
        toast({
          title: "Erro ao autenticar",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true
        });
      });
  };
  return (
    <Box as="form" onSubmit={submitForm} padding={10}>
      <Header
        title="Acessar"
        leftButton={{
          icon: MdArrowBack,
          title: "Voltar",
          onClick: () => history.push("/")
        }}
        styles={{ marginBottom: 10 }}
      />
      <Text marginBottom={10} textAlign="center">
        Para acessar sua conta, digite e-mail e senha abaixo:
      </Text>
      <FormControl marginBottom={5} isRequired>
        <FormLabel htmlFor="email">E-mail</FormLabel>
        <Input
          type="email"
          name="email"
          autoFocus
          id="email"
          placeholder="Digite seu e-mail"
          autoComplete="email"
          size="lg"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl marginBottom={5}>
        <FormLabel htmlFor="password">Senha</FormLabel>
        <InputGroup size="lg">
          <Input
            pr="4.5rem"
            name="password"
            isRequired
            minLength={6}
            type={showPassword ? "text" : "password"}
            placeholder={showPassword ? "Digite sua senha" : "***********"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Box
              cursor="pointer"
              as={showPassword ? MdVisibilityOff : MdVisibility}
              size="26px"
              color="gray.400"
              onClick={togglePassword}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button
        isLoading={isLoading}
        type="submit"
        size="lg"
        variantColor="teal"
        isFullWidth
      >
        Entrar
      </Button>
      <Button
        size="lg"
        marginTop={5}
        variant="link"
        variantColor="teal"
        isFullWidth
        as={Link}
        to="/forgot"
      >
        Esqueceu a senha?
      </Button>
    </Box>
  );
};
