import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import {
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast
} from "@chakra-ui/core";
import Header from "components/Header";
import { firebaseAuth } from "fb";

export default () => {
  const toast = useToast();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    setIsLoading(true);
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(data => {
        toast({
          title: "Link enviado",
          description:
            "Verifique seu e-mail. Clique no link recebido para redefinir sua senha.",
          status: "success",
          duration: 9000,
          isClosable: true
        });
        history.push("/signin");
      })
      .catch(err => {
        setIsLoading(false);
        toast({
          title: "Erro ao enviar link",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true
        });
      });
  };
  return (
    <Box as="form" onSubmit={submitForm} padding={10}>
      <Header
        title="Esqueceu a senha"
        leftButton={{
          icon: MdArrowBack,
          title: "Voltar",
          onClick: () => history.push("/signin")
        }}
        styles={{ marginBottom: 10 }}
      />
      <Text marginBottom={10} textAlign="center">
        Digite seu e-mail abaixo para receber um link para redefinir sua senha:
      </Text>
      <FormControl marginBottom={5} isRequired>
        <FormLabel htmlFor="email">E-mail</FormLabel>
        <Input
          type="email"
          autoFocus
          id="email"
          size="lg"
          placeholder="Digite seu e-mail"
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </FormControl>
      <Button
        isLoading={isLoading}
        type="submit"
        size="lg"
        variantColor="teal"
        isFullWidth
      >
        Enviar
      </Button>
    </Box>
  );
};
