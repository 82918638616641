import React from "react";
import { Flex, Box, Text, Button, Stack } from "@chakra-ui/core";
import { Link } from "react-router-dom";

export default () => {
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="row"
        minHeight={200}
        marginBottom={5}
        backgroundColor="gray.50"
        color="gray.800"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="5xl" as="h1">
          Telemedicina
        </Text>
      </Flex>
      <Box padding={10}>
        <Text textAlign="center" marginBottom={8}>
          Para acessar a plataforma de telemedicina, escolha uma opção:
        </Text>

        <Stack spacing={5} justifyContent="center">
          <Button as={Link} to="/signin" size="lg" variantColor="teal">
            Já tenho cadastro
          </Button>
          <Button as={Link} to="/signup" size="lg" variantColor="teal">
            Quero me cadastrar
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
