import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCMxr3SMPzMubkyFxoUUv6WBXuWHza1ayE",
  authDomain: "telemed-grupomeddi.firebaseapp.com",
  databaseURL: "https://telemed-grupomeddi.firebaseio.com",
  projectId: "telemed-grupomeddi",
  storageBucket: "telemed-grupomeddi.appspot.com",
  messagingSenderId: "265770026916",
  appId: "1:265770026916:web:40f8a04d9b510746446331",
  measurementId: "G-STV6QVESTV"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebaseApp.auth();
export const db = firebase.firestore(firebaseApp);
