import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Text, Stack, useToast } from "@chakra-ui/core";
import { firebaseAuth, db } from "fb";

export default function App(props) {
  const { currentUser = {} } = props;
  const toast = useToast();
  const [clinics, setClinics] = useState();
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (!clinics && userData) {
      return db.collection("clinics").onSnapshot((data) => {
        const newClinics = {};
        console.log("LIST CLINICS");
        data.docs.forEach((doc) => {
          if (userData.cep) console.log({ cep: userData.cep });
          const clinic = doc.data();
          if (Array.isArray(clinic.zipIntervals)) {
            let canAccess = false;
            clinic.zipIntervals.forEach((interval) => {
              if (interval.from <= userData.cep && interval.to >= userData.cep)
                canAccess = true;
            });
            if (canAccess) newClinics[doc.id] = clinic;
          }
        });
        setClinics(newClinics);
      });
    }
  }, [clinics, userData]);

  useEffect(() => {
    if (currentUser.uid && !userData) {
      db.collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          console.log({ userData: doc.data() });
          setUserData(doc.data());
        })
        .catch((err) => {
          console.log({ err });
          setUserData(null);
        });
    }
  }, [userData, currentUser.uid]);

  const goToClinic = (clinicId = null) => {
    setIsLoading(clinicId);
    firebaseAuth.currentUser.getIdToken().then((access_token) => {
      axios({
        url: "/api/login?clinicId=" + clinicId,
        headers: {
          access_token,
        },
      })
        .then((res) => {
          window.location.href = `${
            res.data.clinicLink
          }/?accessToken=${encodeURIComponent(res.data.accessToken)}`;
        })
        .catch((err) => {
          setIsLoading(null);
          console.error(err);
          toast({
            title: "Erro ao acessar clínica",
            description:
              "Não conseguimos gerar seu acesso para a clínica. Por favor tente novamente ou entre em contato com o suporte.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    });
  };

  if (!userData || !clinics) {
    return (
      <Box padding={10}>
        Carregando... Se não abrir em 5 segundos, <a href="/">clique aqui</a>
      </Box>
    );
  }

  return (
    <Box maxWidth="1200px" margin="auto" padding={10}>
      <Text fontSize="xl" fontWeight="bold">
        Olá, {userData.name.split(" ")[0]}!
      </Text>
      <Text>Escolha uma clínica para acessar:</Text>
      <Stack marginY={5} spacing={5}>
        {clinics && Object.keys(clinics).length === 0 && (
          <Button variantColor="teal" isFullWidth disabled>
            Nenhuma clínica disponível na sua região.
          </Button>
        )}
        {clinics &&
          Object.keys(clinics).map((clinicId) => (
            <Button
              onClick={() => goToClinic(clinicId)}
              variantColor="teal"
              isFullWidth
              isLoading={isLoading === clinicId}
              key={`clinic-${clinicId}`}
            >
              {clinics[clinicId].title}
            </Button>
          ))}
      </Stack>
      <Button
        marginTop={10}
        variant="link"
        variantColor="teal"
        isFullWidth
        onClick={() => firebaseAuth.signOut()}
      >
        Sair
      </Button>
    </Box>
  );
}
