import React, { useState } from "react";
import cep from "cep-promise";
import cpfValidator from "validar-cpf";
import validator from "validator";
import { useHistory } from "react-router-dom";
import { MdArrowBack, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { firebaseAuth, db } from "fb";
import {
  Box,
  Text,
  Icon,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
  FormHelperText
} from "@chakra-ui/core";
import Header from "components/Header";

export default () => {
  const toast = useToast();
  const history = useHistory();
  const { isOpen: showPassword, onToggle: togglePassword } = useDisclosure(
    false
  );

  const [data, setData] = useState({
    name: "",
    cpf: "",
    birthdate: "",
    cep: "",
    email: "",
    password: "",
    address: {}
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const changeData = e => {
    const newErrors = { ...errors };
    delete newErrors[e.target.name];
    setErrors(newErrors);

    const newData = { ...data };
    newData[e.target.name] = e.target.value;

    if (
      e.target.name === "cpf" &&
      e.target.value &&
      e.target.value.match(/\d+/g)
    ) {
      const cpf = e.target.value.match(/\d+/g).join("");
      let formattedCpf = cpf;
      if (cpf.length < 3) formattedCpf = cpf;
      else if (cpf.length > 3 && cpf.length < 6)
        formattedCpf = cpf.substr(0, 3) + "." + cpf.substr(3, 3);
      else if (cpf.length > 6 && cpf.length < 10)
        formattedCpf =
          cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3);
      else if (cpf.length > 10)
        formattedCpf =
          cpf.substr(0, 3) +
          "." +
          cpf.substr(3, 3) +
          "." +
          cpf.substr(6, 3) +
          "-" +
          cpf.substr(9, 2);

      if (!cpfValidator(cpf)) {
        setErrors({
          ...errors,
          cpf: "CPF inválido"
        });
      }

      newData.cpf = formattedCpf;
    } else if (e.target.name === "cpf") {
      setErrors({
        ...errors,
        cpf: "Preencha o CPF corretamente."
      });
    }

    if (
      e.target.name === "birthdate" &&
      e.target.value &&
      e.target.value.match(/\d+/g)
    ) {
      const birthdate = e.target.value.match(/\d+/g).join("");
      const day = birthdate.substr(0, 2);
      const month = birthdate.substr(2, 2);
      const year = birthdate.substr(4, 4);
      let formattedBirthdate = birthdate;
      if (birthdate.length < 3) formattedBirthdate = birthdate;
      else if (birthdate.length > 2 && birthdate.length < 5)
        formattedBirthdate =
          birthdate.substr(0, 2) + "/" + birthdate.substr(2, 2);
      else if (birthdate.length > 4)
        formattedBirthdate =
          birthdate.substr(0, 2) +
          "/" +
          birthdate.substr(2, 2) +
          "/" +
          birthdate.substr(4, 4);
      newData.birthdate = formattedBirthdate;

      if (!validator.isISO8601(`${year}-${month}-${day}`, { strict: true })) {
        setErrors({
          ...errors,
          birthdate: "Preencha a data de nascimento no formato dd/mm/aaaa"
        });
      }
    } else if (e.target.name === "birthdate") {
      setErrors({
        ...errors,
        birthdate: "Preencha a data de nascimento no formato dd/mm/aaaa"
      });
    }

    if (e.target.name === "email") {
      if (!validator.isEmail(e.target.value)) {
        setErrors({
          ...errors,
          email: "Preencha corretamente o endereço de e-mail"
        });
      }
    }

    if (
      e.target.name === "cep" &&
      e.target.value &&
      e.target.value.match(/\d+/g) &&
      e.target.value.match(/\d+/g).join("").length === 8
    ) {
      const zip = e.target.value.match(/\d+/g).join("");
      newData.address = {};
      cep(zip)
        .then(address => {
          console.log({ address });
          const newData = { ...data };
          newData.address = address;
          newData.cep = address.cep;
          setData(newData);
        })
        .catch(err => {
          setErrors({ ...errors, cep: "CEP não encontrado." });
        });
    } else if (e.target.name === "cep") {
      newData.address = {};
      setErrors({ ...errors, cep: "Preencha o cep corretamente." });
    }

    setData(newData);
  };

  const submitForm = e => {
    e.preventDefault();
    setIsLoading(true);
    firebaseAuth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(user => {
        db.collection("users")
          .doc(user.user.uid)
          .set(
            {
              name: data.name,
              birthdate: data.birthdate,
              cep: data.cep,
              email: data.email,
              cpf: data.cpf,
              address: data.address
            },
            { merge: true }
          )
          .then(() => {
            history.push("/");
            toast({
              title: "Bem-vindo!",
              description: "Sua conta foi criada com sucesso.",
              status: "success",
              duration: 9000,
              isClosable: true
            });
          })
          .catch(err => {
            toast({
              title: "Erro ao salvar dados",
              description: err.message,
              status: "error",
              duration: 9000,
              isClosable: true
            });
            setIsLoading(false);
          });
      })
      .catch(err => {
        toast({
          title: "Erro ao cadastrar",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true
        });
        setIsLoading(false);
      });
  };

  return (
    <Box as="form" onSubmit={submitForm} padding={10}>
      <Header
        title="Cadastre-se"
        leftButton={{
          icon: MdArrowBack,
          title: "Voltar",
          onClick: () => history.push("/")
        }}
        styles={{ marginBottom: 10 }}
      />
      <Text marginBottom={10} textAlign="center">
        Preencha os campos abaixo para realizar seu cadastro:
      </Text>
      <FormControl marginBottom={5} isRequired isInvalid={errors.name}>
        <FormLabel htmlFor="name">Nome</FormLabel>
        <Input
          type="name"
          minLength={5}
          id="name"
          name="name"
          placeholder="Digite seu nome completo"
          autoComplete="name"
          size="lg"
          value={data.name}
          onChange={changeData}
        />
      </FormControl>
      <FormControl marginBottom={5} isRequired isInvalid={errors.cpf}>
        <FormLabel htmlFor="cpf">CPF</FormLabel>
        <InputGroup size="lg">
          <Input
            type="tel"
            id="cpf"
            name="cpf"
            placeholder="000.000.000-00"
            maxLength={14}
            minLength={10}
            value={data.cpf}
            onChange={changeData}
          />
          {data.cpf.length === 14 && !errors.cpf && (
            <InputRightElement
              children={<Icon name="check" color="green.500" />}
            />
          )}
          {errors.cpf && (
            <InputRightElement
              children={<Icon name="warning" color="red.500" />}
            />
          )}
        </InputGroup>

        {errors.cpf && (
          <FormHelperText color="red.400">{errors.cpf}</FormHelperText>
        )}
      </FormControl>
      <FormControl marginBottom={5} isRequired isInvalid={errors.birthdate}>
        <FormLabel htmlFor="birthdate">Data de nascimento</FormLabel>
        <InputGroup size="lg">
          <Input
            type="tel"
            id="birthdate"
            name="birthdate"
            placeholder="dd/mm/aaaa"
            autoComplete="bday"
            maxLength={10}
            minLength={10}
            value={data.birthdate}
            onChange={changeData}
          />
          {data.birthdate.length === 10 && !errors.birthdate && (
            <InputRightElement
              children={<Icon name="check" color="green.500" />}
            />
          )}
          {data.birthdate.length === 10 && errors.birthdate && (
            <InputRightElement
              children={<Icon name="warning" color="red.500" />}
            />
          )}
        </InputGroup>

        {errors.birthdate && (
          <FormHelperText color="red.400">{errors.birthdate}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        marginBottom={5}
        isRequired
        isInvalid={errors.cep}
        errorBorderColor="red.300"
      >
        <FormLabel htmlFor="cep">CEP</FormLabel>
        <InputGroup size="lg">
          <Input
            type="tel"
            id="cep"
            name="cep"
            placeholder="00000-000"
            autoComplete="postal-code"
            isInvalid={errors.cep}
            maxLength={9}
            minLength={8}
            value={data.cep}
            onChange={changeData}
          />
          {data.address.cep && (
            <InputRightElement
              children={<Icon name="check" color="green.500" />}
            />
          )}
        </InputGroup>
        {data.address.cep && (
          <FormHelperText color="green.400">
            {data.address.street}, {data.address.neighborhood} (
            {data.address.city})
          </FormHelperText>
        )}
        {errors.cep && (
          <FormHelperText color="red.400">{errors.cep}</FormHelperText>
        )}
      </FormControl>
      <FormControl marginBottom={5} isRequired isInvalid={errors.email}>
        <FormLabel htmlFor="email">E-mail</FormLabel>
        <InputGroup size="lg">
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Digite seu e-mail"
            autoComplete="email"
            size="lg"
            value={data.email}
            onChange={changeData}
          />
          {data.email.length > 0 && !errors.email && (
            <InputRightElement
              children={<Icon name="check" color="green.500" />}
            />
          )}
          {errors.email && (
            <InputRightElement
              children={<Icon name="warning" color="red.500" />}
            />
          )}
        </InputGroup>

        {errors.email && (
          <FormHelperText color="red.400">{errors.email}</FormHelperText>
        )}
      </FormControl>
      <FormControl isRequired marginBottom={5} isInvalid={errors.password}>
        <FormLabel htmlFor="password">Senha</FormLabel>
        <InputGroup size="lg">
          <Input
            pr="4.5rem"
            isRequired
            minLength={6}
            type={showPassword ? "text" : "password"}
            placeholder={showPassword ? "Digite sua senha" : "***********"}
            id="password"
            name="password"
            autoComplete="new-password"
            value={data.password}
            onChange={changeData}
          />
          <InputRightElement width="4.5rem">
            <Box
              cursor="pointer"
              as={showPassword ? MdVisibility : MdVisibilityOff}
              size="26px"
              color="gray.400"
              onClick={togglePassword}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button
        isLoading={isLoading}
        isDisabled={Object.keys(errors).length > 0}
        type="submit"
        size="lg"
        variantColor="teal"
        isFullWidth
      >
        Concluir
      </Button>
    </Box>
  );
};
