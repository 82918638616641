import React from "react";
import { Flex, Box, Text } from "@chakra-ui/core";

export default function App(props) {
  const { leftButton = null, title = null, styles = {} } = props;
  return (
    <Flex flexDirection="row" {...styles}>
      {leftButton && (
        <Box
          as={leftButton.icon}
          size="32px"
          marginRight={3}
          cursor="pointer"
          onClick={() => (leftButton.onClick && leftButton.onClick()) || null}
        />
      )}
      {title && (
        <Text fontSize="2xl" textAlign="center" width="100%">
          {title}
        </Text>
      )}
    </Flex>
  );
}
